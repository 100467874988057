<template>
    <main class="page article">
        <section class="offerta">
            <div class="offerta__container">
                <div field="text" class="t-text t-text_md" style="">
                    <br /><br />
                    <br />
                    <!-- <div class="news__content" v-html="returnContent.content"></div> -->
                    <div class="news__content news-content">
                        <h1>
                            {{ returnContent.title }}
                        </h1>

                        <h2>1. Один акцент в образі</h2>
                        <p>Щоб уникнути перевантаженості, зробіть акцент на одній центральній прикрасі. Якщо ви обираєте масивні сережки, краще відмовитися від великого кольє, щоб образ не виглядав перевантаженим. Якщо на руці багато браслетів, краще вибрати мінімалістичні сережки.</p>
                        <div class="news-content__image-row">
                            <a :href="require('@/assets/img/9-porad-ta-pryntsypiv-stylizatsii-prykras-dlia-vashykh-obraziv/2025-03-27 15.05.51.jpg')" class="image-row__item" data-fancybox="gallery"><img src="../assets/img/9-porad-ta-pryntsypiv-stylizatsii-prykras-dlia-vashykh-obraziv/2025-03-27 15.05.51.jpg" alt="Image 1" /></a>
                            <a :href="require('@/assets/img/9-porad-ta-pryntsypiv-stylizatsii-prykras-dlia-vashykh-obraziv/2025-03-27 15.37.35.jpg')" class="image-row__item" data-fancybox="gallery"><img src="../assets/img/9-porad-ta-pryntsypiv-stylizatsii-prykras-dlia-vashykh-obraziv/2025-03-27 15.37.35.jpg" alt="Image 2" /></a>
                            <a :href="require('@/assets/img/9-porad-ta-pryntsypiv-stylizatsii-prykras-dlia-vashykh-obraziv/2025-03-27 21.23.33.jpg')" class="image-row__item" data-fancybox="gallery"><img src="../assets/img/9-porad-ta-pryntsypiv-stylizatsii-prykras-dlia-vashykh-obraziv/2025-03-27 21.23.33.jpg" alt="Image 3" /></a>
                        </div>

                        <h2>2. Мікс металів</h2>
                        <p>Раніше було правило: не змішувати золото і срібло, але сучасна мода дозволяє експерименти.</p>
                        <p>Не бійтеся поєднувати різні фініші металів, комбінація виробів з полярним фінішем виглядає цікаво і сучасно.</p>
                        <p>Щоб міксувати метали, створіть єдиний акцент – наприклад, кілька кілець або браслетів, які повторюють обидва відтінки.</p>

                        <div class="news-content__image-row">
                            <a :href="require('@/assets/img/9-porad-ta-pryntsypiv-stylizatsii-prykras-dlia-vashykh-obraziv/2025-03-27 15.06.12.jpg')" class="image-row__item" data-fancybox="gallery"><img src="../assets/img/9-porad-ta-pryntsypiv-stylizatsii-prykras-dlia-vashykh-obraziv/2025-03-27 15.06.12.jpg" alt="Image 1" /></a>
                            <a :href="require('@/assets/img/9-porad-ta-pryntsypiv-stylizatsii-prykras-dlia-vashykh-obraziv/2025-03-27 21.24.44.jpg')" class="image-row__item" data-fancybox="gallery"><img src="../assets/img/9-porad-ta-pryntsypiv-stylizatsii-prykras-dlia-vashykh-obraziv/2025-03-27 21.24.44.jpg" alt="Image 2" /></a>
                            <a :href="require('@/assets/img/9-porad-ta-pryntsypiv-stylizatsii-prykras-dlia-vashykh-obraziv/2025-03-27 21.24.40.jpg')" class="image-row__item" data-fancybox="gallery"><img src="../assets/img/9-porad-ta-pryntsypiv-stylizatsii-prykras-dlia-vashykh-obraziv/2025-03-27 21.24.40.jpg" alt="Image 3" /></a>
                        </div>
                        <h2>3. Багатошаровість</h2>
                        <p>Багатошаровість – це один із головних трендів в ювелірній моді. Як його правильно використовувати:</p>
                        <ul>
                            <li>Ланцюжки різної довжини – короткий чокер + середній кулон + довгий ланцюжок виглядають гармонійно.</li>
                            <li>Кільця на кількох пальцях – можна комбінувати масивні та тонкі моделі.</li>
                        </ul>
                        <p>Поєднання браслетів – металеві, шкіряні або перлинні – додають цікавий акцент.</p>
                        <div class="news-content__image-row">
                            <a :href="require('@/assets/img/9-porad-ta-pryntsypiv-stylizatsii-prykras-dlia-vashykh-obraziv/2025-03-27 15.06.02.jpg')" class="image-row__item" data-fancybox="gallery"><img src="../assets/img/9-porad-ta-pryntsypiv-stylizatsii-prykras-dlia-vashykh-obraziv/2025-03-27 15.06.02.jpg" alt="Image 1" /></a>
                            <a :href="require('@/assets/img/9-porad-ta-pryntsypiv-stylizatsii-prykras-dlia-vashykh-obraziv/2025-03-27 15.37.54.jpg')" class="image-row__item" data-fancybox="gallery"><img src="../assets/img/9-porad-ta-pryntsypiv-stylizatsii-prykras-dlia-vashykh-obraziv/2025-03-27 15.37.54.jpg" alt="Image 2" /></a>
                        </div>

                        <h2>4. Контраст стилів</h2>
                        <p>Грайте на контрастах! Наприклад, сережки з перлинами додадуть романтичності строгому діловому луку, а масивний металевий браслет зробить жіночний образ більш сміливим. Контрастність додає цікавих акцентів і робить образ непередбачуваним.</p>

                        <div class="news-content__image-row">
                            <a :href="require('@/assets/img/9-porad-ta-pryntsypiv-stylizatsii-prykras-dlia-vashykh-obraziv/2025-03-27 21.34.03.jpg')" class="image-row__item" data-fancybox="gallery"><img src="../assets/img/9-porad-ta-pryntsypiv-stylizatsii-prykras-dlia-vashykh-obraziv/2025-03-27 21.34.03.jpg" alt="Image 1" /></a>
                            <a :href="require('@/assets/img/9-porad-ta-pryntsypiv-stylizatsii-prykras-dlia-vashykh-obraziv/2025-03-27 21.34.17.jpg')" class="image-row__item" data-fancybox="gallery"><img src="../assets/img/9-porad-ta-pryntsypiv-stylizatsii-prykras-dlia-vashykh-obraziv/2025-03-27 21.34.17.jpg" alt="Image 2" /></a>
                            <a :href="require('@/assets/img/9-porad-ta-pryntsypiv-stylizatsii-prykras-dlia-vashykh-obraziv/2025-03-27 21.34.07.jpg')" class="image-row__item" data-fancybox="gallery"><img src="../assets/img/9-porad-ta-pryntsypiv-stylizatsii-prykras-dlia-vashykh-obraziv/2025-03-27 21.34.07.jpg" alt="Image 2" /></a>
                        </div>
                        <h2>5. Відповідність стилю одягу</h2>
                        <p>Також прикраси можуть бути доповнення стилю одягу:</p>
                        <ul>
                            <li>Мінімалістичний стиль – тонкі ланцюжки, геометричні форми, маленькі сережки-гвоздики.</li>
                            <li>Бохо – етнічні прикраси, натуральне каміння, багатошаровість.</li>
                            <li>Класика – перли, витончені браслети, елегантні сережки.</li>
                        </ul>
                        <p>Вечірній стиль – великі сережки, розкішні кольє, блискучі камені.</p>
                        <div class="news-content__image-row">
                            <a :href="require('@/assets/img/9-porad-ta-pryntsypiv-stylizatsii-prykras-dlia-vashykh-obraziv/IMG_4290.jpg')" class="image-row__item" data-fancybox="gallery"><img src="../assets/img/9-porad-ta-pryntsypiv-stylizatsii-prykras-dlia-vashykh-obraziv/IMG_4290.jpg" alt="Image 1" /></a>
                            <a :href="require('@/assets/img/9-porad-ta-pryntsypiv-stylizatsii-prykras-dlia-vashykh-obraziv/IMG_8599.jpg')" class="image-row__item" data-fancybox="gallery"><img src="../assets/img/9-porad-ta-pryntsypiv-stylizatsii-prykras-dlia-vashykh-obraziv/IMG_8599.jpg" alt="Image 2" /></a>
                            <a :href="require('@/assets/img/9-porad-ta-pryntsypiv-stylizatsii-prykras-dlia-vashykh-obraziv/2025-03-27 21.42.25.jpg')" class="image-row__item" data-fancybox="gallery"><img src="../assets/img/9-porad-ta-pryntsypiv-stylizatsii-prykras-dlia-vashykh-obraziv/2025-03-27 21.42.25.jpg" alt="Image 2" /></a>
                        </div>

                        <h2>6. Стилізація масивного браслета</h2>
                        <p>Щоб зробити образ більш стильним, носіть масивні браслети поверх манжетів сорочок, рукавів гольфів светрів або жакетів. Це трендовий прийом стане стильним доповненням образу.</p>
                        <div class="news-content__image-row">
                            <a :href="require('@/assets/img/9-porad-ta-pryntsypiv-stylizatsii-prykras-dlia-vashykh-obraziv/2025-03-27 21.45.25.jpg')" class="image-row__item" data-fancybox="gallery"><img src="../assets/img/9-porad-ta-pryntsypiv-stylizatsii-prykras-dlia-vashykh-obraziv/2025-03-27 21.45.25.jpg" alt="Image 1" /></a>
                            <a :href="require('@/assets/img/9-porad-ta-pryntsypiv-stylizatsii-prykras-dlia-vashykh-obraziv/2025-03-27 21.42.35.jpg')" class="image-row__item" data-fancybox="gallery"><img src="../assets/img/9-porad-ta-pryntsypiv-stylizatsii-prykras-dlia-vashykh-obraziv/2025-03-27 21.42.35.jpg" alt="Image 2" /></a>
                        </div>

                        <h2>7. Принципи роботи з кольоротипом</h2>
                        <p>Прикраси повинні гармоніювати з відтінком вашої шкіри:</p>
                        <ul>
                            <li>Теплий підтон (золотистий, персиковий відтінок шкіри) – ідеально пасує жовте та рожеве золото.</li>
                            <li>Холодний підтон (рожевий, оливковий відтінок шкіри) – найкраще виглядають срібло, біле золото, платина.</li>
                            <li>Якщо ви хочете додати контрасту, обирайте метали, протилежні вашому підтону.</li>
                        </ul>

                        <div class="news-content__image-row">
                            <a :href="require('@/assets/img/9-porad-ta-pryntsypiv-stylizatsii-prykras-dlia-vashykh-obraziv/2025-03-27 15.06.30.jpg')" class="image-row__item" data-fancybox="gallery"><img src="../assets/img/9-porad-ta-pryntsypiv-stylizatsii-prykras-dlia-vashykh-obraziv/2025-03-27 15.06.30.jpg" alt="Image 1" /></a>
                            <a :href="require('@/assets/img/9-porad-ta-pryntsypiv-stylizatsii-prykras-dlia-vashykh-obraziv/2025-03-27 15.06.20.jpg')" class="image-row__item" data-fancybox="gallery"><img src="../assets/img/9-porad-ta-pryntsypiv-stylizatsii-prykras-dlia-vashykh-obraziv/2025-03-27 15.06.20.jpg" alt="Image 2" /></a>
                        </div>
                        <h2>8. Точки уваги</h2>
                        <p>Де ви розміщуєте прикраси, туди автоматично спрямовується увага оточуючих. Тому варто визначити, яку зону ви хочете підкреслити:</p>
                        <ul>
                            <li>Довгу шию – підкреслять витончені ланцюжки або подовжені сережки.</li>
                            <li>Декольте – стане виразним з елегантним кольє або підвіскою.</li>
                            <li>Тонкі кисті рук і пальці – привернуть увагу витончені каблучки та браслети.</li>
                            <li>Обличчя – яскраві сережки або оригінальні кафи можуть зробити акцент.</li>
                        </ul>

                        <p>Прикраси – це не просто деталі, а інструмент самовираження. Експериментуйте, міксуйте стилі та метали, створюйте унікальні комбінації! Дотримуючись цих порад, ви зможете зробити свої образи ще більш гармонійними та стильними.</p>
                        <div class="news-content__image-row">
                            <a :href="require('@/assets/img/9-porad-ta-pryntsypiv-stylizatsii-prykras-dlia-vashykh-obraziv/2025-03-27 15.06.35.jpg')" class="image-row__item" data-fancybox="gallery"><img src="../assets/img/9-porad-ta-pryntsypiv-stylizatsii-prykras-dlia-vashykh-obraziv/2025-03-27 15.06.35.jpg" alt="Image 1" /></a>
                            <a :href="require('@/assets/img/9-porad-ta-pryntsypiv-stylizatsii-prykras-dlia-vashykh-obraziv/2025-03-27 15.37.28.jpg')" class="image-row__item" data-fancybox="gallery"><img src="../assets/img/9-porad-ta-pryntsypiv-stylizatsii-prykras-dlia-vashykh-obraziv/2025-03-27 15.37.28.jpg" alt="Image 2" /></a>
                            <a :href="require('@/assets/img/9-porad-ta-pryntsypiv-stylizatsii-prykras-dlia-vashykh-obraziv/2025-03-27 15.37.40.jpg')" class="image-row__item" data-fancybox="gallery"><img src="../assets/img/9-porad-ta-pryntsypiv-stylizatsii-prykras-dlia-vashykh-obraziv/2025-03-27 15.37.40.jpg" alt="Image 2" /></a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </main>
</template>

<script setup>
import { ref, onMounted } from "vue";
import axios from "axios";
import { useRoute } from "vue-router";
import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";

const returnContent = ref("");
const route = useRoute();
const slug = ref(null);

onMounted(async () => {
    slug.value = route.params.slug;
});

const fetchReturnContent = async () => {
    try {
        const response = await axios.get(`${process.env.VUE_APP_PROD_API_URL}page/${slug.value}`);
        returnContent.value = response.data;
    } catch (error) {
        console.error("Error fetching offerta content:", error);
    }
};

onMounted(() => {
    fetchReturnContent();
    Fancybox.bind("[data-fancybox]", {});
});
</script>
<style lang="scss" scoped>
.news-content {
    h1 {
        font-size: 2rem;
        line-height: 2rem;
        text-align: center;
        font-weight: 600;
        margin-bottom: 20px;
    }
    h2 {
        font-size: 1.5rem;
        margin-top: 20px;
        margin-bottom: 10px;
    }

    p {
        line-height: 24px;
    }

    ul {
        list-style: none;
        padding-left: 0;
        margin-bottom: 20px;

        li {
            margin-bottom: 10px;
            position: relative;
            padding-left: 20px;
            line-height: 20px;

            &::before {
                content: "•";
                position: absolute;
                left: 0;
                color: #000; // Цвет маркера
            }
        }
    }

    @media (max-width: 768px) {
        h1 {
            font-size: 1.5rem;
            line-height: 1.5rem;
        }
        h2 {
            font-size: 1.25rem;
        }
    }

    margin: 0 auto;
    margin-bottom: 50px;
    max-width: 900px;
    font-size: 16px;
    display: flex;
    flex-direction: column;
    gap: 16px;

    .image-row__item {
        width: 100px;
        height: auto;
        margin: 5px;

        img {
            width: 100%;
            height: 100%;
        }
    }

    &__image-row {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: nowrap; // Изображения в ряд

        .image-row__item {
            flex-grow: 1; // Изображения растягиваются равномерно
            width: auto; // Убираем фиксированную ширину
            height: 23rem;
            object-fit: cover;
            margin: 5px;
            max-width: calc(100% / 3 - 10px); // Для равномерного распределения с учетом отступов

            @media (max-width: $mobile) {
                height: 37vw;
            }
        }
    }

    @media (max-width: $mobile) {
        gap: 10px;

        p {
            line-height: 20px;
        }

        h1 {
            margin-bottom: 0;
        }
    }
}
</style>
