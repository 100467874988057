import { createRouter, createWebHistory } from "vue-router";
import HomePage from "../components/HomePage.vue";
import ProductPage from "../components/ProductPage.vue";
import OrderPage from "../components/OrderPage.vue";
import CatalogPage from "../components/CatalogPage.vue";
import ProductSearch from "../components/ProductSearch.vue";
import FavoritePage from "../components/FavoritePage.vue";
import CertificatesPage from "../components/CertificatesPage.vue";
import OffertaPage from "../components/OffertaPage.vue";
import DostavkaPage from "../components/DostavkaPage.vue";
import ContactsPage from "../components/ContactsPage.vue";
import ThanksPage from "../components/ThanksPage.vue";
import ReturnPoilcy from "../components/ReturnPoilcy.vue";
import RouletteComponent from "../components/RouletteComponent.vue";
import NotFoundPage from "../components/404.vue";
import ArticlePage from "../pages/ArticlePage.vue";

const routes = [
    { path: "/", component: HomePage, meta: { title: "Le-perle", description: "Le'Perle - український бренд прикрас, створений в епоху війни, втілює місію принести красу і надію в серця людей" } },
    {
        path: '/product/:slug/:vendor_code?',
        name: "Product",
        component: ProductPage,
        meta: {
            title: (route) => `${route.params.slug} – Le-perle`,
            description: "Le'Perle - український бренд прикрас, створений в епоху війни, втілює місію принести красу і надію в серця людей",
        },
    },
    { path: "/order", component: OrderPage, meta: { title: "Замовити – Le-perle", description: "Le'Perle - український бренд прикрас, створений в епоху війни, втілює місію принести красу і надію в серця людей" } },
    { path: "/catalog/:categoryId", name: "Catalog", component: CatalogPage, meta: { title: "Каталог – Le-perle", description: "Le'Perle - український бренд прикрас, створений в епоху війни, втілює місію принести красу і надію в серця людей" } },
    { path: "/catalog/:categoryId/:subCategorySlug:", name: "SubCatalog", component: CatalogPage, meta: { title: "Каталог – Le-perle", description: "Le'Perle - український бренд прикрас, створений в епоху війни, втілює місію принести красу і надію в серця людей" } },
    { path: "/search/:name", name: "ProductSearch", component: ProductSearch, meta: { title: "Пошук – Le-perle", description: "Le'Perle - український бренд прикрас, створений в епоху війни, втілює місію принести красу і надію в серця людей" } },
    { path: "/wishlist", component: FavoritePage, meta: { title: "Сподобалось – Le-perle", description: "Le'Perle - український бренд прикрас, створений в епоху війни, втілює місію принести красу і надію в серця людей" } },
    { path: "/certificate", name: "Certificate", component: CertificatesPage, meta: { title: "Сертифікати – Le-perle", description: "Le'Perle - український бренд прикрас, створений в епоху війни, втілює місію принести красу і надію в серця людей" } },
    { path: "/offerta", name: "Offerta", component: OffertaPage, meta: { title: "Договір публічної оферти – Le-perle", description: "Le'Perle - український бренд прикрас, створений в епоху війни, втілює місію принести красу і надію в серця людей" } },
    { path: "/return-policy", name: "return-policy", component: ReturnPoilcy },
    { path: "/delivery", name: "Delivery", component: DostavkaPage, meta: { title: "Політика повернення – Le-perle", description: "Le'Perle - український бренд прикрас, створений в епоху війни, втілює місію принести красу і надію в серця людей" } },
    { path: "/contacts", name: "Contacts", component: ContactsPage, meta: { title: "Контакти – Le-perle", description: "Le'Perle - український бренд прикрас, створений в епоху війни, втілює місію принести красу і надію в серця людей" } },
    { path: "/thanks/:orderId", name: "Thanks", component: ThanksPage, meta: { title: "Дякуємо! – Le-perle", description: "Le'Perle - український бренд прикрас, створений в епоху війни, втілює місію принести красу і надію в серця людей" } },
    { path: "/RouletteComponent", name: "RouletteComponent", component: RouletteComponent },
    { path: "/:pathMatch(.*)*", name: "NotFoundPage", component: NotFoundPage },
    { path: "/news/:slug", name: "ArticlePage", component: ArticlePage },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    async scrollBehavior(to, from, savedPosition) {
        // Если сохраненная позиция существует, вернуть её
        if (savedPosition) {
            return savedPosition;
        }

        // Если URL изменился, но путь остался прежним
        if (to.path === from.path) {
            return false;
        }

        return new Promise((resolve) => {
            let position = { top: 0 };
            window.scrollTo({
                top: position.top,
                behavior: "smooth",
            });
            resolve(position);
        });
    },
});


router.beforeEach((to, from, next) => {
    const title = typeof to.meta.title === "function" ? to.meta.title(to) : to.meta.title;
    document.title = title || "Le-perle";

    const metaDescription = document.querySelector('meta[name="description"]');
    if (metaDescription) {
        metaDescription.setAttribute("content", to.meta.description || "Le-perle");
    }

    next();
});

// function generateSitemap() {
//     const sitemapRoutes = routes
//         .map((r) => r.path)
//         .filter((r) => !r.includes(":")) // Удаление маршрутов с параметрами
//         .map((r) => `<url><loc>https://le-perle.com.ua${r}</loc></url>`);
//     console.log(`
//         <?xml version='1.0' encoding='UTF-8'?>
//         <urlset xmlns='http://www.sitemaps.org/schemas/sitemap/0.9'>
//             ${sitemapRoutes.join("\n")}
//         </urlset>
//     `);
// }

// generateSitemap();

export default router;
