<template>
    <transition name="slide">
        <div v-if="isOpen" class="popup-addreview">
            <div class="popup-addreview__container">
                <div class="popup-addreview__body">
                    <button class="popup-addreview__close _icon-close" @click="closeAddReview"></button>
                    <div class="popup-addreview__content">
                        <div class="popup-addreview__top">
                            <div class="popup-addreview__title">{{ title }}</div>
                            <div class="popup-addreview__subtitle">Будемо раді отримати відгук.</div>
                        </div>

                        <div class="popup-addreview__form popup-addreview-form">
                            <form class="popup-addreview-form__form" @submit.prevent="submitForm">
                                <div class="popup-addreview-form__section">
                                    <FormGroup v-model="form.phone" id="phone" type="text" placeholder="Телефон" :error="errors.phone" />
                                    <FormGroup v-model="form.email" id="email" type="text" placeholder="Email" :error="errors.email" />
                                </div>
                                <div class="popup-addreview-form__section">
                                    <FormGroup v-model="form.review" id="review" type="text" placeholder="Відгук" :error="errors.review" />
                                </div>
                                <div class="popup-addreview-form__complete">
                                    <button type="submit" class="button popup-addreview-form__submit">Додати</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script setup>
import { defineProps, defineEmits, watch, reactive } from "vue";
import "vue-toast-notification/dist/theme-bootstrap.css";
import { useToast } from "vue-toast-notification";
import FormGroup from "./FormGroup.vue";

const props = defineProps({
    isOpen: Boolean,
    title: String,
    product_id: Number,
});

const apiUrl = process.env.VUE_APP_PROD_API_URL;

const emit = defineEmits(["update:isOpen"]);

const closeAddReview = () => {
    emit("update:isOpen", false);
};

const blockScroll = () => {
    document.body.style.overflow = "hidden";
};

const unblockScroll = () => {
    document.body.style.overflow = "";
};

// Watcher для блокировки/разблокировки скролла
watch(
    () => props.isOpen,
    (newVal) => {
        if (newVal) {
            blockScroll();
        } else {
            unblockScroll();
        }
    }
);

const toast = useToast();

const form = reactive({
    phone: "",
    email: "",
    review: "",
});

const errors = reactive({
    phone: "",
    email: "",
    review: "",
});

const validateForm = () => {
    let isValid = true;
    let errorFields = [];

    // Сброс ошибок
    Object.keys(errors).forEach((key) => {
        errors[key] = "";
    });

    // Валидация персональных данных
    if (!form.phone) {
        errors.phone = "Будь ласка, введіть телефон";
        isValid = false;
        errorFields.push("Телефон");
    }
    if (!form.email) {
        errors.email = "Будь ласка, введіть електрону пошту";
        isValid = false;
        errorFields.push("Email");
    }
    if (!form.review) {
        errors.review = "Будь ласка, введіть відгук";
        isValid = false;
        errorFields.push("Відгук");
    }

    if (!isValid) {
        // Отображение ошибок через toast
        toast.error(`<b>Заповніть поля: </b><br>${errorFields.join("<br> ")}`, {
            position: "top-right",
        });
    }

    return isValid;
};

const submitForm = async () => {
    if (validateForm()) {
        const orderData = {
            firstName: form.firstName,
            phone: form.phone,
            email: form.email,
            review: form.review,
            product_id: props.product_id,
        };

        try {
            fetch(`${apiUrl}review/create`, {
                method: "POST",
                body: JSON.stringify(orderData),
            });

            toast.success("Дякуємо за Ваш відгук!", {
                position: "top-right",
            });
            closeAddReview();

            // if (response.ok) {
            //     // const responseData = await response.json();

            //     // Очистка формы и корзины
            //     Object.keys(form).forEach((key) => (form[key] = ""));
            //     // Добавьте логику очистки корзины
            //     // Например: clearCart();
            //     closeAddReview(); // Закрыть окно после успешной отправки формы
            // } else {
            //     const errorData = await response.json();
            //     toast.error(`Помилка при оформленні замовлення: ${errorData.message}`, {
            //         position: "top-right",
            //     });
            // }
        } catch (error) {
            console.error("Error submitting order:", error);
            toast.error("Сталася помилка при відправці.", {
                position: "top-right",
            });
        }
    } else {
        console.log("Form is invalid");
    }
};
</script>
<style lang="scss" scoped>
.popup-addreview {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;

    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(25, 36, 52, 0.16);
        z-index: -1;
    }

    &__container {
        width: 500px;
        max-width: 90vw;
        height: auto;
        background-color: #fff;
        box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.25);
        border-radius: 8px;
        position: relative;
        display: flex;
        flex-direction: column;
        padding: 0;
    }

    &__body {
        padding: 24px;
        position: relative;
    }

    &__close {
        position: absolute;
        top: 20px;
        right: 20px;
        background-color: transparent;
        border: none;
        cursor: pointer;
        i {
            font-size: 24px;
            color: #000;
        }
    }

    &__title {
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 28px; /* 140% */
        text-transform: uppercase;
        color: #000;
        margin: 0;
        margin-bottom: 12px;
    }

    &__subtitle {
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; /* 150% */
        color: #000;
    }

    &__top {
        margin-bottom: 16px;
    }
}

.popup-addreview-form {
    &__section {
        display: flex;
        gap: 16px;

        &:not(:last-child) {
            margin-bottom: 16px;
        }
    }

    &__complete {
        display: flex;
        justify-content: flex-end;
        margin-top: 24px;
    }

    &__submit {
        width: 100%;
        padding: 15px 0;
    }
}

/* Анимационные классы для перехода */
.slide-enter-active,
.slide-leave-active {
    transition: transform 0.3s ease;
}

.slide-enter-from {
    transform: translateY(100%); /* Начальное состояние при входе */
}

.slide-enter-to {
    transform: translateY(0); /* Конечное состояние при входе */
}

.slide-leave-from {
    transform: translateY(0); /* Начальное состояние при выходе */
}

.slide-leave-to {
    transform: translateY(100%); /* Конечное состояние при выходе */
}
</style>
