<template>
    <div :class="{ 'form-group': true, '--error': error }">
        <input :value="modelValue" :id="id" :type="type" :placeholder="placeholder" :class="{ 'is-invalid': error }" :name="name" @input="updateValue" @change="updateValue" :checked="isChecked" />
        <label v-if="type === 'radio' || type === 'checkbox'" :for="id">{{ placeholder }}</label>
        <span v-if="error" class="error-msg">{{ error }}</span>
    </div>
</template>

<script setup>
import { defineProps, defineEmits, computed } from "vue";

const props = defineProps({
    modelValue: {
        type: [String, Number, Boolean],
        default: "",
    },
    id: {
        type: String,
        required: true,
    },
    type: {
        type: String,
        default: "text",
    },
    name: {
        type: String,
        default: "",
    },
    placeholder: {
        type: String,
        default: "",
    },
    error: {
        type: String,
        default: "",
    },
    value: {
        type: [String, Number, Boolean],
        default: "",
    },
});

const emit = defineEmits(["update:modelValue"]);

const isChecked = computed(() => {
    if (props.type === "checkbox" || props.type === "radio") {
        return props.modelValue === props.value;
    }
    return false;
});

const updateValue = (event) => {
    if (props.type === "checkbox") {
        emit("update:modelValue", event.target.checked ? props.value : "");
    } else if (props.type === "radio") {
        emit("update:modelValue", props.value);
    } else {
        emit("update:modelValue", event.target.value);
    }
};
</script>

<style lang="scss" scoped>
.form-group {
    display: flex;
    position: relative;
    align-items: center;
    gap: 16px;
    width: 100%;

    &.--error {
        margin-bottom: 10px;
    }
}

input[type="checkbox"] {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 16px;
    height: 16px;
    border: 2px solid #000;
    border-radius: 4px;
    position: relative;
    cursor: pointer;

    &:checked {
        background-color: #000;
        border-color: #000;
    }

    &:checked::after {
        content: "";
        position: absolute;
        left: 4px;
        top: 2px;
        width: 4px;
        height: 8px;
        border: solid white;
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);
    }
}

input[type="radio"] {
    margin: 0;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 20px;
    height: 20px;
    border: 2px solid #000;
    border-radius: 100%;
    position: relative;
    cursor: pointer;

    &:checked {
        border-color: #000;

        &::after {
            content: "";
            display: block;
            width: 10px; /* Размер внутреннего кружка */
            height: 10px; /* Размер внутреннего кружка */
            background-color: #000;
            border-radius: 50%;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }
}

input[type="text"],
select {
    height: 56px;
    width: 100%;
    padding: 16px 12px;
    border-radius: 4px;
    border: 1px solid #ececec;
    background: #fff;

    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */

    color: #000;
}

input.is-invalid,
select.is-invalid {
    border-color: red;
}

.error-msg {
    color: red;
    font-size: 10px;
    margin-top: 5px;
    position: absolute;
    top: 100%;
}

label {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    color: #000;
}
</style>
