<template>
    <div class="sizes" v-if="data.data.length > 0 && data.data[0] !== null && data.data.some((size) => size !== '')">
        <div class="sizes__title">{{ title }}:</div>
        <div class="sizes__items">
            <div class="sizes__item" v-for="(item, index) in data.data" :key="index" :class="{ active: activeIndex === index }" @click="selectSize(index)">
                {{ item.option_value }}
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, watch, computed } from "vue";
import { defineProps, defineEmits } from "vue";

const props = defineProps({
    data: Object,
    selectedSize: String, // Новый пропс для выбранного размера
});

const emit = defineEmits(["selected"]);

const activeIndex = ref(0);

const title = computed(() => {
    const label = props.data.label.toLowerCase();
    if (label.includes("розмір")) {
        return "Оберіть розмір";
    } else if (label.includes("довжина")) {
        return "Оберіть довжину";
    } else if (label.includes("застібка")) {
        return "Оберіть тип застібки";
    } else {
        return `Оберіть ${label}`;
    }
});

watch(
    () => props.selectedSize,
    (newSize) => {
        if (newSize) {
            activeIndex.value = props.data.data.indexOf(newSize);
        }
    },
    { immediate: true }
);

const selectSize = (index) => {
    activeIndex.value = index;
    emit("selected", props.data.data[index]);
};
</script>

<style lang="scss" scoped>
.sizes__item.active {
    /* Добавьте стили для активного элемента */
}
.content-product__sizes {
    margin-bottom: 0;
}

.content-product {
}

.sizes {
    &__item {
        width: 45px !important;
        height: 45px !important;

        @media (max-width: $mobile) {
            font-size: 11px !important;
            width: 35px !important;
            height: 35px !important;
        }
    }
}
</style>
