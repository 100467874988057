<template>
    <div class="top-page">
        <div class="top-page__container">
            <div class="top-page__breadcrumbs breadcrumbs">
                <ul class="breadcrumbs__list">
                    <li class="breadcrumbs__item"><router-link to="/" class="breadcrumbs__link">Le’Perle</router-link></li>
                    <li class="breadcrumbs__item" v-for="(item, index) in breadcrumbs" :key="index">
                        <router-link v-if="item.slug" :to="{ name: 'Catalog', params: { slug: item.slug } }" class="breadcrumbs__link">{{ item.title }}</router-link>
                        <span v-else class="breadcrumbs__current">{{ item.title }}</span>
                    </li>
                </ul>
            </div>
            <div class="top-page__body">
                <h1 class="top-page__title" :data-title="currentCategoryTitleEn">{{ currentCategoryTitle }}</h1>

                <div class="top-page__control">
                    <Sorting @sortingChanged="handleSortingChange" :categoryId="categoryId" />

                    <button class="top-page__button top-page__button_hide _icon-filter" @click="toggleOpenFilter">Фільтри</button>
                </div>
            </div>
        </div>
    </div>

    <section class="catalog">
        <div class="catalog__container">
            <div class="catalog__body">
                <Filter v-if="categoryId > 0" class="catalog__filter" :categoryId="categoryId" :isFilterOpen="isFilterOpen" :closeFilter="toggleOpenFilter" @filterChange="handleFilterChange" @priceChange="handlePriceChange" @inStockChange="handleStockChange" @availableForOrderChange="handleAvailableForOrderChange" />
                <div class="catalog__content">
                    <div class="catalog__items product-items">
                        <ProductCart v-for="(product, index) in products" :key="index" :product="product" @updateShowVariants="updateShowVariants(index, $event)" />
                    </div>
                    <div class="more">
                        <!-- <div class="more__pagination pagination-more">
                            <a href="#" class="pagination-more__item" v-for="page in totalPages" :key="page" :class="{ active: currentPage === page }" @click.prevent="handlePageChange(page)">{{ page }}</a>
                        </div> -->

                        <div class="more__pagination pagination-more">
                            <a href="#" class="pagination-more__item" v-for="page in paginate()" :key="page" :class="{ active: currentPage === page }" @click.prevent="page !== '...' && handlePageChange(page)">
                                {{ page }}
                            </a>
                        </div>

                        <div class="more__body">
                            <button class="more__button button" v-if="currentPage < totalPages" @click="loadMore">Показати більше</button>

                            <div class="more__view">
                                переглянуто
                                <b>{{ products.length }}</b>
                                товарів з
                                <b>{{ totalProducts }}</b>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <ProductSlider :title="'Інші клієнти також купили'" :products="viewedProducts" id="1" />
    <ProductSlider :title="'Останні переглянуті товари'" :products="viewedProducts" id="2" />
</template>

<script>
import Filter from "./Filter.vue";
import Sorting from "./Sorting.vue";
import ProductCart from "./ProductCart.vue";
import ProductSlider from "./ProductSlider.vue";
import "vue-toast-notification/dist/theme-bootstrap.css";
import { useToast } from "vue-toast-notification";
import { useRouter } from "vue-router";
import axios from "axios";

export default {
    components: {
        Filter,
        Sorting,
        ProductCart,
        ProductSlider,
    },
    data() {
        return {
            apiUrl: process.env.VUE_APP_PROD_API_URL,
            isFilterOpen: false,
            products: [],
            showVariantsStates: [],
            breadcrumbs: [],
            currentCategoryTitle: "",
            currentCategoryTitleEn: "",
            filters: {},
            currentPage: 1,
            totalPages: 0,
            totalProducts: 0,
            viewedProducts: [],
            categoryId: 0,
        };
    },
    setup() {
        const toast = useToast();
        const router = useRouter();

        return { toast, router };
    },
    mounted() {
        this.currentPage = this.$route.query.page ? parseInt(this.$route.query.page) : 1;
        this.getCategoryInfo();
        this.getProducts();
        this.getViewProducts();
        // this.fbCatalog();
    },
    computed: {
        countShowVariants() {
            return this.showVariantsStates.filter((state) => state).length;
        },
    },
    methods: {
        toggleOpenFilter() {
            this.isFilterOpen = !this.isFilterOpen;
        },
        handleFilterChange(filter) {
            if (filter.checked) {
                if (!this.filters[filter.id]) {
                    this.filters[filter.id] = [];
                }
                this.filters[filter.id].push(filter.value);
            } else {
                if (this.filters[filter.id]) {
                    const index = this.filters[filter.id].indexOf(filter.value);
                    if (index > -1) {
                        this.filters[filter.id].splice(index, 1);
                    }
                    if (this.filters[filter.id].length === 0) {
                        delete this.filters[filter.id];
                    }
                }
            }
            this.currentPage = 1;

            // Заменяем текущий URL новым, включающим активные фильтры
            this.$router.replace({ query: { ...this.$route.query, filters: JSON.stringify(this.filters) } }).then(() => this.getProducts());
        },
        handlePriceChange(prices) {
            this.currentPage = 1;

            // Заменяем текущий URL новым, включающим ценовые фильтры
            this.$router.replace({ query: { ...this.$route.query, prices: JSON.stringify(prices) } }).then(() => this.getProducts());
        },
        handleSortingChange(sorting) {
            this.currentPage = 1;

            // Заменяем текущий URL новым, включающим сортировку
            this.$router.replace({ query: { ...this.$route.query, sorting } }).then(() => this.getProducts());
        },
        handleStockChange(inStock) {
            this.currentPage = 1;

            // Заменяем текущий URL новым, включающим сортировку
            this.$router.replace({ query: { ...this.$route.query, inStock: inStock } }).then(() => this.getProducts());
        },
        handleAvailableForOrderChange(ailableForOrderChange) {
            this.currentPage = 1;

            // Заменяем текущий URL новым, включающим сортировку
            this.$router.replace({ query: { ...this.$route.query, ailableForOrderChange: ailableForOrderChange } }).then(() => this.getProducts());
        },

        async getCategoryInfo() {
            var subCategory = this.$route.params.subCategorySlug ? "/" + this.$route.params.subCategorySlug : "";

            try {
                const response = await this.axios.get(this.apiUrl + "categories/" + this.$route.params.categoryId + subCategory, {});
                const data = response.data.data;

                if (data) {
                    this.breadcrumbs = [];
                    var parent = data.parent ? data.parent.title + " – " : "";
                    if (data.parent) {
                        this.breadcrumbs.push({
                            title: data.parent.title,
                            slug: data.parent.slug,
                        });
                    }
                    this.breadcrumbs.push({
                        title: data.title,
                        slug: "",
                    });

                    this.categoryId = data.id;
                    this.currentCategoryTitle = parent + data.title;
                    this.currentCategoryTitleEn = data.title_en;
                    this.fbCatalog();

                    await this.getProducts();
                } else {
                    this.currentCategoryTitle = "";
                    this.currentCategoryTitleEn = "";
                }
            } catch (error) {
                console.error(error);
                if (error.response && error.response.status === 404) {
                    this.$router.push({ name: "NotFoundPage" });
                }
            }
        },
        async getProducts(append = false) {
            if (this.categoryId === 0) return;
            const filters = this.$route.query.filters || this.filters;
            const prices = this.$route.query.prices ? JSON.parse(this.$route.query.prices) : { minPrice: this.minPrice, maxPrice: this.maxPrice };
            const sorting = this.$route.query.sorting || (this.categoryId === 2 ? "latest" : "default");
            const inStock = this.$route.query.inStock;
            const availableForOrderChange = this.$route.query.ailableForOrderChange;

            try {
                const response = await this.axios.get(this.apiUrl + "products", {
                    params: {
                        category_id: this.categoryId,
                        filters: filters,
                        min_price: prices.minPrice,
                        max_price: prices.maxPrice,
                        in_stock: inStock,
                        available_for_order: availableForOrderChange,
                        page: this.currentPage,
                        sorting: sorting,
                    },
                });

                if (append) {
                    response.data.data.forEach((newProduct) => {
                        this.products.push(newProduct);
                    });
                } else {
                    this.products = response.data.data;
                }

                this.currentPage = response.data.meta.current_page;
                this.totalPages = response.data.meta.last_page;
                this.totalProducts = response.data.meta.total;
            } catch (error) {
                console.error(error);
            }
        },

        handlePageChange(page) {
            this.currentPage = page;
            // Заменяем текущий URL новым, включающим номер страницы
            this.$router.replace({ query: { ...this.$route.query, page: this.currentPage } }).then(() => this.getProducts());
        },

        loadMore() {
            if (this.currentPage < this.totalPages) {
                this.currentPage += 1;
                // Заменяем текущий URL новым, включающим номер страницы
                this.$router.replace({ query: { ...this.$route.query, page: this.currentPage } }).then(() => this.getProducts(true));
            }
        },

        getViewProducts() {
            let storedProducts = localStorage.getItem("viewedProducts");
            if (storedProducts) {
                this.viewedProducts = JSON.parse(storedProducts);
            }

            return this.viewedProducts;
        },

        fbCatalog() {
            const getCookie = this.$getCookie;
            const getEventId = this.$getEventId;
            const event_id = getEventId();
            const external_id = localStorage.getItem("external_id");

            window.fbq("track", "ViewCategory", {
                content_category: this.currentCategoryTitle, // Здесь вы передаёте текущую категорию
                content_ids: this.products.map((product) => product.id).join(","), // Здесь мы собираем id всех товаров в текущей категории
                content_name: this.currentCategoryTitle,
                event_id: event_id,
                external_id: external_id,
                content_type: "product",
                source: "le-perle.com.ua",
            });

            try {
                axios.post(this.apiUrl + "fbapi", {
                    action: "ViewCategory",
                    event_id: event_id,
                    content_category: this.currentCategoryTitle, // Здесь вы передаёте текущую категорию
                    content_ids: this.products.map((product) => product.id).join(","), // Здесь мы собираем id всех товаров в текущей категории
                    content_name: this.currentCategoryTitle,
                    quantity: 1,
                    fbc: getCookie("_fbc"),
                    fbp: getCookie("_fbp"),
                    external_id: external_id,
                    useragent: window.navigator.userAgent,
                    url: `${window.location.origin}${window.location.pathname}`,
                });
            } catch (error) {
                console.error(error);
            }
        },

        handleRouteChange() {
            this.filters = {};
            this.getCategoryInfo();
            this.fbCatalog();
            this.$router.replace({ query: { ...this.$route.query, filters: JSON.stringify(this.filters) } }).then(() => this.getProducts());
        },
        paginate() {
            let pages = [];

            if (this.totalPages <= 10) {
                // Если страниц меньше или равно 10, показываем все
                for (let i = 1; i <= this.totalPages; i++) {
                    pages.push(i);
                }
            } else {
                // Добавляем первую страницу
                pages.push(1);

                // Определяем диапазон отображаемых страниц вокруг текущей страницы
                let startPage = Math.max(this.currentPage - 2, 2);
                let endPage = Math.min(startPage + 4, this.totalPages - 1);

                if (this.currentPage - 1 <= 3) {
                    endPage = 6;
                } else if (this.totalPages - this.currentPage <= 3) {
                    startPage = this.totalPages - 5;
                }

                // Добавляем "..."
                if (startPage > 2) {
                    pages.push("...");
                }

                // Добавляем страницы между первой и последней
                for (let i = startPage; i <= endPage; i++) {
                    pages.push(i);
                }

                // Добавляем "..." и последнюю страницу, если необходимо
                if (endPage < this.totalPages - 1) {
                    pages.push("...");
                }
                pages.push(this.totalPages);
            }

            return pages;
        },

        updateShowVariants(index, value) {
            this.showVariantsStates[index] = value;
            if (this.showVariantsStates.every((state) => state === false)) {
                this.$store.commit("openCart");
            }
        },
    },
    watch: {
        "$route.params.categoryId": function (newVal, oldVal) {
            if (newVal !== oldVal) {
                this.handleRouteChange();
            }
        },
        "$route.params.subCategorySlug": function (newVal, oldVal) {
            if (newVal !== oldVal) {
                this.handleRouteChange();
            }
        },

        "$route.query.page": function (newVal, oldVal) {
            if (newVal !== oldVal) {
                this.currentPage = newVal ? parseInt(newVal) : 1;
                // this.getProducts();
            }
        },
    },
};
</script>

<style></style>
